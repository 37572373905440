import './src/styles/tailwind.css';
import wrapWithProvider from "./wrap-with-provider"

/** *** gatsby-browser.js *** 
 *
 * Implement Gatsby's Browser APIs in this file.
 *
 * @see: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 * @see https://github.com/gatsbyjs/gatsby/blob/master/examples/using-redux/gatsby-ssr.js
 */

export const wrapRootElement = wrapWithProvider