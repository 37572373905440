import React, { useState, createContext, useEffect } from "react";


/**  *** Provider Language *** */

export const ContextLanguage = createContext();

// the provider
export function ProviderLanguage({ children }) {
  const baseLang = "de"
  const [lang, setLang] = useState(baseLang)
  const contextValue = { lang, setLang };


  useEffect(() => {
    const route = window.location.pathname;
    const baseLang = route.includes("/en/") ? "en" : "de";
    setLang(baseLang);
  }, []);

  return <ContextLanguage.Provider value={contextValue}>{children}</ContextLanguage.Provider>;
}
