import React from "react"
import { ProviderLanguage } from "./src/components/providerLanguage";


/** *** wrap-with-provider.js *** 
 * 
 * This file wraps the root element with the ViewportFontsizeProvider and the BackgroundColorProvider.
 * @see gatsby-browser.js
 * @see gatsby-ssr.js
 * @see https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/#wrapRootElement
*/


// eslint-disable-next-line react/display-name,react/prop-types
const RootWrapper = ({ element }) => (
  <ProviderLanguage>{element}</ProviderLanguage>
)

export default RootWrapper;