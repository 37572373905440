module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"./images/favicon/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"ff8a3c3c34a822ba0f8c643d7587331f"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Klangwerkstatt Berlin 2024, Festival für neue Musik","short_name":"Klangwerkstatt Berlin 2024","description":"Festival für Neue Musik, Berlin — 8. bis 17. November 2024","start_url":"https://www.klangwerkstatt-berlin.de/2024/","background_color":"#FFFFFF","theme_color":"#FFFFFF","display":"standalone","icon":"images/favicon/icon.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"57d6f1eb9227868fceb2fc74eb17f04a"},
    },{
      plugin: require('../node_modules/gatsby-omni-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"enableListener":true,"preconnect":["https://use.typekit.net/"],"web":[{"name":"aktiv-grotesk","file":"https://use.typekit.net/mzx0cvu.css"}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
